import React, { useMemo } from 'react';
import cn from 'classnames';
import type { Id } from 'types';

import SmartLink from 'components/SmartLink';
import type { AnalyticAttrs } from 'services/GoogleAnalytics';
import { getTransformedDataTestId } from 'utils';

interface Props {
    id: Id;
    iconsLeft?: JSX.Element[];
    iconsRight?: JSX.Element[];
    isDisabled?: boolean;
    isSuccess?: boolean;
    isSelected?: boolean;
    children: string | JSX.Element;
    actionHandler?: string | object | ((id: Id) => void);
    customClassName?: string;
    contentClassName?: string;
    analytic?: AnalyticAttrs;
    dataTest?: string;
}

const DropdownElement: React.FC<Props> = ({
    id,
    iconsLeft,
    iconsRight,
    isDisabled,
    isSuccess,
    isSelected,
    children,
    actionHandler,
    customClassName,
    contentClassName,
    analytic = {},
    dataTest,
}) => {
    const handleOnClick = () => {
        if (typeof actionHandler !== 'function') return;

        actionHandler(id);
    };

    const dropdownElementAttr = useMemo(
        () =>
            getTransformedDataTestId({
                defaultAttribute: 'dropdown-element',
                propsAttribute: dataTest,
                isActive: isSelected,
            }),
        [dataTest, isSelected]
    );

    const content = (
        <>
            {iconsLeft}

            <span
                className={cn('grow typo-sm-2', contentClassName)}
                data-test="dropdown-element__content"
            >
                {children}
            </span>

            {iconsRight}
        </>
    );
    const dropdownElementClasses = cn(
        'flex cursor-pointer items-center rounded-default p-1 hover:bg-surface-middle',
        {
            ['fill-grey-500 text-grey-500 hover:fill-grey-300 hover:text-grey-300']:
                !isSuccess && !isDisabled && !isSelected,
            ['fill-primary-orange-toxic text-primary-orange-toxic']: isSuccess,
            ['pointer-events-none cursor-default fill-grey-700 text-grey-700']:
                isDisabled,
            ['fill-primary-white text-primary-white hover:fill-grey-300 hover:text-grey-300']:
                isSelected,
        },
        customClassName
    );

    if (actionHandler && typeof actionHandler !== 'function') {
        return (
            <SmartLink
                href={actionHandler}
                className={cn(
                    'no-underline focus-visible:fill-grey-300 focus-visible:text-grey-300 focus-visible:outline-none',
                    dropdownElementClasses
                )}
                analyticsAttrs={{
                    'data-category': analytic['data-category'],
                    'data-action': analytic['data-action'],
                    'data-label': analytic['data-label'],
                }}
                dataTest={dropdownElementAttr}
            >
                {content}
            </SmartLink>
        );
    }

    return (
        <div
            className={dropdownElementClasses}
            onClick={handleOnClick}
            data-category={analytic['data-category']}
            data-action={analytic['data-action']}
            data-label={analytic['data-label']}
            data-value={id}
            data-test={dropdownElementAttr}
        >
            {content}
        </div>
    );
};

export default React.memo(DropdownElement);
