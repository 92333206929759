import { FC, memo } from 'react';

import { Feature, useFeatureFlags } from 'services/features';
import MatchListWithSubSport from '../MatchListWithSubSport/MatchListWithSubSport';
import ClientMatchList, { Props as ClientMatchListProps } from './MatchList';
import { Props as MatchListProps } from './MatchListContent';

type Props = Omit<MatchListProps, 'limit' | 'nextPageHandler'> &
    Pick<ClientMatchListProps, 'getMatchListVariables'>;

const HybridMatchList: FC<Props> = ({
    excludeIds,
    isHiddenNoResults,
    getMatchListVariables,
    position,
    withBanners,
}) => {
    const { features } = useFeatureFlags();
    const isSubSportFeature = features.isEnabled(Feature.MatchListSubSport);

    const MatchList = isSubSportFeature
        ? MatchListWithSubSport
        : ClientMatchList;

    return (
        <MatchList
            getMatchListVariables={getMatchListVariables}
            isHiddenNoResults={isHiddenNoResults}
            excludeIds={excludeIds}
            pagePropsKey="sportEvents"
            position={position}
            withBanners={withBanners}
        />
    );
};

export default memo(HybridMatchList);
