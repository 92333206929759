import { useReactiveVar } from '@apollo/client';

import { GetMatchListVariables } from 'components/betting/MatchList';
import { sportIdsBySportType } from 'components/betting/MatchList/utils';
import { sortOrder } from 'components/betting/SportEventSort/sportEventSortState';
import { SortOrder } from 'components/betting/SportEventSort/types';
import { useRouter } from 'components/HybridRouter';
import { useIsMobile } from 'components/utilities/MobileDetect';
import { useDeepMemo } from 'hooks';
import { useBettingLayout } from 'layouts/BettingLayout/BettingLayoutContext';
import { GetSportEventListByFiltersVariables } from 'types/gql.bet';

interface Input {
    getMatchListVariables: GetMatchListVariables;
}

const useMatchListVariables = ({
    getMatchListVariables,
}: Input): GetSportEventListByFiltersVariables => {
    const sportEventsSortOrder: SortOrder = useReactiveVar(sortOrder);
    const { isMobile } = useIsMobile();
    const { query, asPath } = useRouter();
    const { sportIds } = useBettingLayout();

    return useDeepMemo(
        () => ({
            order: sportEventsSortOrder,
            ...getMatchListVariables({
                isMobile,
                query,
                sportIds: sportIdsBySportType(asPath, sportIds),
                url: asPath,
            }),
        }),

        [getMatchListVariables, query, sportEventsSortOrder, asPath]
    );
};

export default useMatchListVariables;
