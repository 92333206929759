export enum MatchListType {
    ALL = 'all',
    LIVE = 'live',
    NEXTTOGO = 'next-to-go',
}

export const MATCH_LIST_EVENTS_COUNT = 12 as const;

export const SSR_SPORT_EVENTS_LIMIT_MOBILE = 4;
export const SSR_SPORT_EVENTS_LIMIT_DESKTOP = 6;
