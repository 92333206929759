import { GetServerSidePropsWithApolloClients } from 'services/apollo';
import { GetServerSidePageProps } from 'types';
import { getServerMatchList } from './MatchList';
import { GetMatchListVariables } from './types';

export const getServerMatchListData =
    (
        getMatchListVariables: GetMatchListVariables
    ): GetServerSidePropsWithApolloClients =>
    async (ctx): Promise<GetServerSidePageProps> => {
        const { gsspData, messages } = await getServerMatchList(
            getMatchListVariables,
            'no-cache'
        )(ctx);

        const { sportEvents } = gsspData || {};

        if (!sportEvents) {
            return {
                props: { messages },
            };
        }

        return {
            props: {
                gsspData: {
                    sportEvents,
                },
                messages,
            },
        };
    };
