import dynamic from 'next/dynamic';

const OverviewBannersCarousel = dynamic(
    () =>
        import(
            /* webpackChunkName: "OverviewBannersCarousel" */ './OverviewBannersCarousel'
        ),
    { ssr: false }
);

export default OverviewBannersCarousel;
