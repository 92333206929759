import type { ComponentType, FC, MutableRefObject, ReactNode } from 'react';

import { typedReactMemo } from 'utils';

type Props<T, R> = {
    isWrapped: boolean;
    wrapper: ComponentType<T>;
    refWrapper?: R;
    children: ((renderProps: any) => ReactNode) | ReactNode | undefined;
} & T;

const ConditionalWrapper = <T, R extends MutableRefObject<any>>({
    isWrapped,
    children,
    refWrapper,
    wrapper: Wrapper,
    ...rest
}: Props<T, R>): ReturnType<FC> => {
    const props = rest as T & JSX.IntrinsicAttributes;

    if (!isWrapped) {
        return typeof children === 'function' ? (
            <>{children(props)}</>
        ) : (
            <>{children}</>
        );
    }

    return (
        <Wrapper ref={refWrapper} {...props}>
            {typeof children === 'function'
                ? (renderProps: any) => children(renderProps)
                : children}
        </Wrapper>
    );
};

export default typedReactMemo(ConditionalWrapper);
