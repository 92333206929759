import React, { FC, memo } from 'react';
import cn from 'classnames';
import { map } from 'lodash';

import DropdownElement from 'components/DropdownElement';
import PackIcon, { IconsPack } from 'components/PackIcon';
import { Action, Category } from 'services/GoogleAnalytics/constants';
import { capitalizeFirstLetter } from 'utils';
import { SortOrder } from './types';
import useSportEventSort from './useSportEventSort';

const contentClassName = cn('w-full truncate');

const SportEventSort: FC = () => {
    const {
        clickOutsideRef,
        handleSetActiveSort,
        activeSort,
        isOpen,
        toggleDropdown,
        rankOptions,
    } = useSportEventSort();

    return (
        <div ref={clickOutsideRef} className="relative h-[36px] w-[145px]">
            <DropdownElement
                id={activeSort}
                actionHandler={toggleDropdown}
                isSelected={true}
                customClassName={cn(
                    'group h-full pl-4 pr-2',
                    isOpen
                        ? 'bg-surface-light !text-primary-orange-toxic hover:bg-surface-light'
                        : 'bg-surface-middle !text-grey-500 hover:!text-primary-white'
                )}
                contentClassName={contentClassName}
                iconsRight={[
                    <div key="arrowIcon" className="min-w-[24px] max-w-[24px]">
                        <PackIcon
                            id="branch-more-up"
                            pack={IconsPack.SpriteIcons}
                            className={cn(
                                'w-full',
                                'transition-transform',
                                'duration-300',
                                isOpen
                                    ? 'rotate-0 fill-primary-orange-toxic'
                                    : 'rotate-180 fill-grey-500 group-hover:fill-primary-white'
                            )}
                        />
                    </div>,
                ]}
            >
                <span>{capitalizeFirstLetter(rankOptions[activeSort])}</span>
            </DropdownElement>

            <div
                className={cn(
                    'absolute right-0 z-max mt-1 w-[180px] rounded-default bg-surface-middle p-2 shadow-dropdown',
                    {
                        hidden: !isOpen,
                    }
                )}
            >
                {map(rankOptions, (value: string, key: SortOrder) => {
                    const isSelected = activeSort === key;

                    return (
                        <DropdownElement
                            id={key}
                            actionHandler={() => handleSetActiveSort(key)}
                            key={key}
                            analytic={{
                                'data-category': Category.LineSportEvent,
                                'data-action': Action.LineSorting,
                                'data-label': key,
                            }}
                            customClassName={cn(
                                'h-8 p-0 px-2 hover:bg-surface-light',
                                {
                                    '!text-primary-orange-toxic': isSelected,
                                }
                            )}
                            contentClassName={contentClassName}
                            iconsRight={
                                isSelected
                                    ? [
                                          <div key="arrowIcon">
                                              <PackIcon
                                                  id="check"
                                                  pack={IconsPack.ActionIcons}
                                                  className="rotate-0 fill-primary-orange-toxic"
                                              />
                                          </div>,
                                      ]
                                    : undefined
                            }
                            isSelected={isSelected}
                        >
                            {capitalizeFirstLetter(value)}
                        </DropdownElement>
                    );
                })}
            </div>
        </div>
    );
};

export default memo(SportEventSort);
