import { FC, memo } from 'react';

import OverviewBannersCarousel from 'components/betting/Promo/OverviewBannersCarousel';
import { useRouter } from 'components/HybridRouter';
import { withClientRender } from 'hocs';
import { AnalyticAttrsCtx, Category } from 'services/GoogleAnalytics';
import { AppLink } from 'types/applink';

const BANNER_POSITION = 3 as const;

const ANALYTIC = {
    'data-category': Category.BannerOverview,
};

const WithBanners: FC<{
    children: Array<JSX.Element>;
    position?: number;
    isShowBanner?: boolean;
}> = ({ children, position, isShowBanner }) => {
    const { pathname } = useRouter();

    const withBanner = pathname === AppLink.BETS;

    if (!withBanner) {
        return <>{children}</>;
    }

    const prev = children.slice(0, position || BANNER_POSITION);
    const after = children.slice(position || BANNER_POSITION, children.length);

    if (withBanner && isShowBanner) {
        prev.push(
            <AnalyticAttrsCtx.Provider value={ANALYTIC} key="Banner_Slider">
                <OverviewBannersCarousel />
            </AnalyticAttrsCtx.Provider>
        );
    }

    return <>{[...prev, ...after]}</>;
};

export default memo(
    withClientRender(WithBanners, ({ children }) => {
        return <>{children}</>;
    })
);
