import { FC, memo } from 'react';
import { useTranslations } from 'next-intl';

import SmartLink from 'components/SmartLink';
import { GetMatchListVariables } from '../MatchList';
import MatchList from '../MatchList/MatchList';
import ShowMoreButton from '../ShowMoreButton/ShowMoreButton';
import useMatchListWithSubSport from './useMatchListWithSubSport';

interface Props {
    excludeIds?: string[];
    isHiddenNoResults?: boolean;
    getMatchListVariables: GetMatchListVariables;
    position?: number;
    withBanners?: boolean;
}

const MatchListWithSubSport: FC<Props> = ({
    excludeIds,
    isHiddenNoResults,
    getMatchListVariables,
    position,
    withBanners,
}) => {
    const {
        withSubSportMatchList,
        subSportUrl,
        subSportName,
        isSubSportEventsExist,
        getSubSportMatchListVariables,
    } = useMatchListWithSubSport({ getMatchListVariables });

    const t = useTranslations('betting-global.global');

    return (
        <>
            <MatchList
                getMatchListVariables={getMatchListVariables}
                isHiddenNoResults={isHiddenNoResults}
                excludeIds={excludeIds}
                isSubSportEventsExist={isSubSportEventsExist}
                pagePropsKey="sportEvents"
                withBanners={withBanners}
                position={position}
            />
            {withSubSportMatchList && (
                <>
                    <SmartLink
                        className="my-4 flex font-bold text-primary-white"
                        href={subSportUrl}
                    >
                        {subSportName}
                    </SmartLink>
                    <MatchList
                        getMatchListVariables={getSubSportMatchListVariables}
                        withPagination={false}
                        moreButton={
                            <ShowMoreButton
                                text={t('moreMatches')}
                                href={subSportUrl}
                            />
                        }
                        withBanners={withBanners}
                        position={position}
                    />
                </>
            )}
        </>
    );
};

export default memo(MatchListWithSubSport);
