import React from 'react';

import ProgressLine from './ProgressLine';
import useLoader from './useLoader';

interface Props {
    initialProgressValue: number;
    isLoader: boolean;
}

const Loader: React.FC<Props> = ({ isLoader, initialProgressValue }) => {
    const { isVisibleProgressBar, progressValue } = useLoader({
        isLoader,
        initialProgressValue,
    });

    return isVisibleProgressBar ? (
        <ProgressLine percent={progressValue} />
    ) : null;
};

export default React.memo(Loader);
