import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { withClientRender } from 'hocs';

interface Props {
    children: React.ReactNode;
    onNextPage: (page: number) => Promise<void>;
    limitPerPage: number;
    lengthList: number;
    spinner?: JSX.Element | ((isLoading: boolean) => JSX.Element);
}

const Pagination: React.FC<Props> = ({
    children,
    onNextPage,
    limitPerPage,
    lengthList,
    spinner,
}) => {
    const [page, setPage] = useState<number>(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { ref } = useInView({
        threshold: 1,
        delay: 100,
        onChange: (inView) => {
            const isNoNextPage = !!(lengthList % limitPerPage);

            if (!inView || isNoNextPage) return;

            setIsLoading(true);

            onNextPage(page)
                .then(() => {
                    setPage((prevPage) => prevPage + 1);
                })
                .finally(() => {
                    setIsLoading(false);
                })
                .catch(console.error);
        },
    });

    const loader = typeof spinner === 'function' ? spinner(isLoading) : spinner;

    return (
        <>
            {children}
            <div ref={ref} />
            {isLoading && loader && <div className="mx-auto">{loader}</div>}
        </>
    );
};

export default React.memo(
    withClientRender(Pagination, ({ children }) => {
        return <>{children}</>;
    })
);
