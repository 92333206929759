import { RefObject, useCallback, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { getCookie, setCookie } from 'cookies-next';
import { mapValues } from 'lodash';
import { useTranslations } from 'next-intl';

import { StorageKey } from 'app-constants';
import { useClickOutside, useToggle } from 'hooks';
import { RankOrderVariants } from './constants';
import { sortOrder } from './sportEventSortState';
import { SortOrder } from './types';

interface Output {
    isOpen: boolean;
    toggleDropdown: VoidFunction;
    clickOutsideRef: RefObject<HTMLDivElement>;
    handleSetActiveSort: (key: SortOrder) => void;
    activeSort: SortOrder;
    rankOptions: { [K in SortOrder]: string };
}

const useSportEventSort = (): Output => {
    const tBettingFilters = useTranslations(
        'betting-filters.filters.SortEventOrder'
    );
    const tBettingGlobal = useTranslations('betting-global.global');

    const [isOpen, toggleDropdown, closeDropdown] = useToggle();

    const sportEventsSortOrder: SortOrder = useReactiveVar(sortOrder);

    const clickOutsideRef = useClickOutside(closeDropdown);

    const rankOptions = mapValues(RankOrderVariants, (v) => {
        if (v === 'recommended') {
            return tBettingFilters(v);
        }

        return tBettingGlobal(v);
    });

    useEffect(() => {
        const currentCookie = getCookie(
            StorageKey.SPORT_EVENT_ORDER
        ) as SortOrder;

        if (!currentCookie || !RankOrderVariants[currentCookie]) return;

        sortOrder(currentCookie);
    }, []);

    const handleSetActiveSort = useCallback(
        (key: SortOrder) => {
            setCookie(StorageKey.SPORT_EVENT_ORDER, key);
            sortOrder(key);
            closeDropdown();
        },
        [closeDropdown]
    );

    return {
        handleSetActiveSort,
        clickOutsideRef,
        activeSort: sportEventsSortOrder,
        isOpen,
        toggleDropdown,
        rankOptions,
    };
};

export default useSportEventSort;
