import React from 'react';
import { inRange } from 'lodash';

type StrokeLinecap = 'round' | 'butt' | 'square' | 'inherit';

interface DefaultProps {
    strokeWidth: number;
    strokeColor: string;
    trailWidth: number;
    trailColor: string;
    strokeLinecap: StrokeLinecap;
    transition: string;
}

type NeedProps = {
    percent: number;
};

type Props = Partial<DefaultProps> & NeedProps;

const ProgressLine: React.FC<Props> = ({
    transition = 'stroke-dashoffset 0.6s ease 0s, stroke 0.6s ease',
    percent,
    strokeWidth = 1,
    trailWidth,
    strokeLinecap = 'square',
    strokeColor = '#3FC7FA',
    trailColor = '#D9D9D9',
}) => {
    const pathStyle = {
        strokeDasharray: '100px, 100px',
        strokeDashoffset: `${100 - percent}px`,
        transition,
    };

    const validatedStrokeWidth =
        strokeWidth && inRange(strokeWidth, 0, 100) ? strokeWidth : 1;

    const validatedTrailWidth = trailWidth || validatedStrokeWidth;

    const center = validatedStrokeWidth / 2;
    const right = 100 - validatedStrokeWidth / 2;
    const viewBoxString = `0 0 100 ${validatedStrokeWidth}`;
    let pathString;

    if (strokeLinecap !== 'butt') {
        pathString = `M ${center},${center} L ${right},${center}`;
    } else {
        pathString = `M ${0},${center} L ${100},${center}`;
    }

    return (
        <svg
            className="block h-[2px] w-full"
            viewBox={viewBoxString}
            preserveAspectRatio="none"
        >
            <path
                className="stroke-current"
                d={pathString}
                strokeLinecap={strokeLinecap}
                stroke={trailColor}
                strokeWidth={validatedTrailWidth}
                fillOpacity="0"
            />

            <path
                className="stroke-primary-orange-toxic"
                d={pathString}
                strokeLinecap={strokeLinecap}
                stroke={strokeColor}
                strokeWidth={validatedStrokeWidth}
                fillOpacity="0"
                style={pathStyle}
            />
        </svg>
    );
};

export default ProgressLine;
