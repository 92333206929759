import React, { FC, useMemo } from 'react';
import { useTranslations } from 'next-intl';

import Empty from 'components/Empty/Empty';
import { useRouter } from 'components/HybridRouter';
import { MatchListType } from './constants';
import { getMatchListTypeByUrl } from './utils';

interface EmptyStateInfo {
    title: string;
    message?: string;
}

interface Props {
    isSubSportEventsExist?: boolean;
}

const MatchListEmpty: FC<Props> = ({ isSubSportEventsExist }) => {
    const { asPath } = useRouter();

    const t = useTranslations('betting-components.components');
    const tMatchList = useTranslations('betting-matchlist.matchList');

    const matchType = getMatchListTypeByUrl(asPath);
    const emptyTextLiveUpcomingMessage = isSubSportEventsExist
        ? tMatchList('emptyTextLiveUpcoming')
        : undefined;

    const getEmptyState: EmptyStateInfo = useMemo(() => {
        switch (matchType) {
            case MatchListType.LIVE:
                return {
                    title: tMatchList('emptyTitleLive'),
                    message: emptyTextLiveUpcomingMessage,
                };
            case MatchListType.NEXTTOGO:
                return {
                    title: tMatchList('emptyTitleUpcoming'),
                    message: emptyTextLiveUpcomingMessage,
                };
            default:
                return {
                    title: t('NoResults'),
                    message: undefined,
                };
        }
    }, [t, tMatchList, matchType, emptyTextLiveUpcomingMessage]);

    return (
        <Empty
            title={getEmptyState.title}
            message={getEmptyState.message}
            icon="/spa-assets/image/empty-state/bets-chicken.png"
        />
    );
};

export default MatchListEmpty;
