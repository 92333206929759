import { FC, memo } from 'react';
import { FetchPolicy } from '@apollo/client';
import { getCookie } from 'cookies-next';

import { StorageKey } from 'app-constants';
import {
    RankOrderVariants,
    SortOrder,
} from 'components/betting/SportEventSort';
import { getTypeDevice } from 'components/utilities/MobileDetect';
import type { PagePropsKeys } from 'services/apollo';
import { WithApolloClientsContext } from 'services/apollo';
import { GetServerSidePagePropsData } from 'types';
import { GetSportEventListByFilters, SportEventOrder } from 'types/gql.bet';
import { getMessages } from 'utils';
import getSettledResult from 'utils/getSettledResult';
import {
    SSR_SPORT_EVENTS_LIMIT_DESKTOP,
    SSR_SPORT_EVENTS_LIMIT_MOBILE,
} from './constants';
import { getServerPageGetSportEventListByFilters } from './getSportEventListByFilters.bet';
import MatchListContent from './MatchListContent';
import type { GetMatchListVariables } from './types';
import useMatchList from './useMatchList';
import { sportIdsBySportType } from './utils';

export const MATCH_LIST_MESSAGES: (keyof Messages)[] = [
    'betting-filters',
    'betting-matchlist',
];

export interface Props {
    getMatchListVariables: GetMatchListVariables;
    excludeIds?: string[];
    isHiddenNoResults?: boolean;
    isSubSportEventsExist?: boolean;
    withPagination?: boolean;
    moreButton?: JSX.Element;
    pagePropsKey?: PagePropsKeys;
    withBanners?: boolean;
    position?: number;
}

type TupleSettledResult = [GetSportEventListByFilters, { [p: string]: {} }];

const MatchList: FC<Props> = ({
    getMatchListVariables,
    isHiddenNoResults,
    excludeIds,
    isSubSportEventsExist,
    withPagination = true,
    moreButton,
    pagePropsKey,
    withBanners,
    position,
}) => {
    const { nextPageHandler, limit, sportEvents } = useMatchList({
        getMatchListVariables,
        pagePropsKey,
    });

    return (
        <MatchListContent
            isHiddenNoResults={isHiddenNoResults}
            sportEvents={sportEvents}
            nextPageHandler={nextPageHandler}
            limit={limit}
            excludeIds={excludeIds}
            isSubSportEventsExist={isSubSportEventsExist}
            withPagination={withPagination}
            moreButton={moreButton}
            position={position}
            withBanners={withBanners}
        />
    );
};

const makeGetServerSidePropsFunction =
    (getMatchListVariables: GetMatchListVariables, fetchPolicy?: FetchPolicy) =>
    () =>
        getServerPageGetSportEventListByFilters(async (ctx) => {
            const { isMobile } = getTypeDevice(ctx);
            const orderCookie = getCookie(StorageKey.SPORT_EVENT_ORDER, {
                req: ctx.req,
            }) as SportEventOrder;

            const { resolvedUrl, sportIds = [] } = ctx;

            let order = SportEventOrder.RankRecommended;

            if (orderCookie && RankOrderVariants[orderCookie as SortOrder]) {
                order = orderCookie;
            }

            return {
                fetchPolicy,
                variables: {
                    order,
                    isClient: false,
                    ...getMatchListVariables({
                        query: ctx.query,
                        isMobile,
                        sportIds: sportIdsBySportType(resolvedUrl, sportIds),
                        url: ctx.resolvedUrl,
                    }),
                    limit: isMobile
                        ? SSR_SPORT_EVENTS_LIMIT_MOBILE
                        : SSR_SPORT_EVENTS_LIMIT_DESKTOP,
                },
                context: {
                    important: true,
                },
            };
        });

export const getServerMatchList =
    (getMatchListVariables: GetMatchListVariables, fetchPolicy?: FetchPolicy) =>
    async (
        ctx: WithApolloClientsContext
    ): Promise<GetServerSidePagePropsData> => {
        const getMatchListHandler = makeGetServerSidePropsFunction(
            getMatchListVariables,
            fetchPolicy
        )();

        const [sportEvents, messages] =
            await getSettledResult<TupleSettledResult>({
                promises: [
                    getMatchListHandler(ctx),
                    getMessages(ctx, MATCH_LIST_MESSAGES),
                ],
                label: 'getServerMatchList',
                errorMessage: 'getServerMatchList request failed',
                request: ctx.req,
            });

        return {
            gsspData: { sportEvents },
            messages,
        };
    };

export default memo(MatchList);
