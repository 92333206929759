import { useEffect, useRef, useState } from 'react';

interface Input {
    initialProgressValue: number;
    isLoader: boolean;
}

interface Output {
    progressValue: number;
    isVisibleProgressBar: boolean;
}

const DELAY = 1500 as const;
const INTERVAL = 500 as const;
const PROGRESS_VALUE_STEP = 2 as const;
const MAX_PROGRESS_VALUE = 100 as const;

const useLoader = ({ initialProgressValue, isLoader }: Input): Output => {
    const initialProgressValueRef = useRef<number>(initialProgressValue);
    initialProgressValueRef.current = initialProgressValue;

    const [progressValue, setProgressValue] = useState<number>(
        initialProgressValueRef.current
    );
    const [isVisibleProgressBar, setIsVisibleProgressBar] =
        useState<boolean>(false);

    useEffect(() => {
        let timeout: number | undefined;

        if (isLoader) {
            setIsVisibleProgressBar(true);
        } else {
            setProgressValue(MAX_PROGRESS_VALUE);

            timeout = window.setTimeout(() => {
                setIsVisibleProgressBar(false);
            }, DELAY);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [isLoader]);

    useEffect(() => {
        if (!isVisibleProgressBar) {
            setProgressValue(initialProgressValueRef.current);

            return;
        }

        const interval = window.setInterval(() => {
            setProgressValue((prevValue) => {
                const nextValue = prevValue + PROGRESS_VALUE_STEP;

                if (nextValue > MAX_PROGRESS_VALUE) {
                    clearInterval(interval);

                    return prevValue;
                }

                return nextValue;
            });
        }, INTERVAL);

        return () => {
            clearInterval(interval);
        };
    }, [isVisibleProgressBar]);

    return {
        isVisibleProgressBar,
        progressValue,
    };
};

export default useLoader;
