import { useCallback, useEffect } from 'react';
import { max } from 'lodash';
import type { SportEventsInList } from 'types';

import {
    PollerName,
    useSyncPolling,
} from 'layouts/BettingLayout/SyncPollingProvider';
import type { PagePropsKeys } from 'services/apollo';
import { useGetSportEventListByFilters } from './getSportEventListByFilters.bet';
import type { GetMatchListVariables } from './types';
import useMatchListVariables from './useMatchListVariables';

type SportEvents = SportEventsInList | undefined;

interface Output {
    limit: number;
    nextPageHandler: (page: number) => Promise<void>;
    sportEvents: SportEvents;
}

interface Input {
    getMatchListVariables: GetMatchListVariables;
    pagePropsKey?: PagePropsKeys;
}

const useMatchList = ({
    getMatchListVariables,
    pagePropsKey,
}: Input): Output => {
    const { addPoller, removePoller } = useSyncPolling({
        pollerName: PollerName.MatchList,
    });

    const variables = useMatchListVariables({ getMatchListVariables });

    const { data, fetchMore, refetch, previousData, error } =
        useGetSportEventListByFilters(() => ({
            variables,
            fetchPolicy: 'cache-and-network',
            returnPartialData: true,
            pagePropsKey,
        }));

    const sportEvents = data?.matches.sportEvents;
    const previosSportEvents = previousData?.matches.sportEvents;

    const memoizedRefetch = useCallback(
        () =>
            refetch({
                ...variables,
                offset: 0,
                limit: max([variables.limit, sportEvents?.length]),
            }),
        [sportEvents?.length, refetch, variables]
    );

    useEffect(() => {
        addPoller({ poller: memoizedRefetch });

        return () => removePoller();
    }, [removePoller, memoizedRefetch, addPoller]);

    const nextPageHandler = useCallback(
        async (page: number) => {
            await fetchMore({
                variables: {
                    ...variables,

                    offset: (page || 0) * variables.limit,
                },
            });
        },

        [variables, fetchMore]
    );

    const { limit } = variables;

    return {
        nextPageHandler,
        limit,
        sportEvents: sportEvents || (error ? [] : previosSportEvents),
    };
};

export default useMatchList;
