import React, { FC, memo } from 'react';
import type { SportEventsInList } from 'types';

import type { AnalyticAttrs } from 'services/GoogleAnalytics';
import { AnalyticAttrsCtx, Category } from 'services/GoogleAnalytics';
import SportEventList from '../SportEventList';
import MatchListEmpty from './MatchListEmpty';

const ANALYTIC: AnalyticAttrs = {
    'data-category': Category.LineSportEvent,
};

export interface Props {
    excludeIds?: string[];
    isHiddenNoResults?: boolean;
    sportEvents?: SportEventsInList;
    limit: number;
    nextPageHandler: (page: number) => Promise<void>;
    isSubSportEventsExist?: boolean;
    withPagination?: boolean;
    moreButton?: JSX.Element;
    withBanners?: boolean;
    position?: number;
}

const MatchListContent: FC<Props> = ({
    excludeIds,
    isHiddenNoResults = false,
    sportEvents,
    limit,
    nextPageHandler,
    isSubSportEventsExist,
    withPagination = true,
    moreButton,
    withBanners,
    position,
}) => {
    if (isHiddenNoResults && sportEvents?.length === 0) return null;

    if (sportEvents && sportEvents.length === 0) {
        return <MatchListEmpty isSubSportEventsExist={isSubSportEventsExist} />;
    }

    return (
        <AnalyticAttrsCtx.Provider value={ANALYTIC}>
            <SportEventList
                nextPageHandler={nextPageHandler}
                limit={limit}
                sportEvents={sportEvents || []}
                excludeIds={excludeIds}
                withPagination={withPagination}
                moreButton={moreButton}
                withBanners={withBanners}
                position={position}
            />
        </AnalyticAttrsCtx.Provider>
    );
};

export default memo(MatchListContent);
