import { FC, memo, useCallback } from 'react';
import { reduce } from 'lodash';
import type { PartialMatch } from 'types';

import { useRouter } from 'components/HybridRouter';
import { AppLink } from 'types/applink';
import ConditionalWrapper from '../../ConditionalWrapper';
import Loader from '../Loader';
import Pagination from '../Pagination';
import SportEventRow, { HeaderType } from '../SportEventRow';
import WithBanners from './WithBanners';

interface Props {
    nextPageHandler: (page: number) => Promise<void>;
    limit: number;
    sportEvents: PartialMatch[];
    excludeIds?: string[];
    withPagination?: boolean;
    moreButton?: JSX.Element;
    withBanners?: boolean;
    position?: number;
}

const SportEventList: FC<Props> = ({
    nextPageHandler,
    limit,
    sportEvents,
    excludeIds = [],
    withPagination = true,
    moreButton,
    withBanners,
    position,
}) => {
    const { pathname } = useRouter();
    const makeOnSpinnerHandler = useCallback(() => {
        return function OnSpinnerHandler(isLoading: boolean): JSX.Element {
            return <Loader isLoader={isLoading} initialProgressValue={50} />;
        };
    }, []);

    if (sportEvents.length === 0) {
        return null;
    }

    const isTournamentPage =
        pathname === AppLink.ESPORTS_TOURNAMENT_SLUG ||
        pathname === AppLink.SPORTS_TOURNAMENT_SLUG;

    return (
        <div className="flex-row align-middle" data-test="sport-event-list">
            <ConditionalWrapper
                isWrapped={withPagination}
                wrapper={Pagination}
                limitPerPage={limit}
                onNextPage={nextPageHandler}
                lengthList={sportEvents.length}
                spinner={makeOnSpinnerHandler()}
            >
                <WithBanners position={position} isShowBanner={withBanners}>
                    {reduce<PartialMatch, JSX.Element[]>(
                        sportEvents,
                        (
                            acc,
                            {
                                id,
                                fixture,
                                streams,
                                markets,
                                slug,
                                meta,
                                version,
                            },
                            index
                        ) => {
                            if (excludeIds?.includes(id)) return acc;

                            const prevTournamentId =
                                sportEvents[index - 1]?.fixture.tournament.id;

                            const headerType =
                                prevTournamentId !== fixture.tournament.id &&
                                !isTournamentPage
                                    ? HeaderType.Tournament
                                    : HeaderType.None;

                            acc.push(
                                <SportEventRow
                                    key={id}
                                    fixture={fixture}
                                    streams={streams}
                                    markets={markets}
                                    sportEventId={id}
                                    headerType={headerType}
                                    slug={slug}
                                    meta={meta}
                                    version={version}
                                />
                            );

                            return acc;
                        },
                        []
                    )}
                </WithBanners>
            </ConditionalWrapper>
            {!withPagination && moreButton}
        </div>
    );
};

export default memo(SportEventList);
