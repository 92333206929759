import { useCallback, useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { LinkProps as NextLinkProps } from 'next/dist/client/link';

import { ACTIVE_SPORTEVENT_STATUSES, AppLink } from 'app-constants';
import {
    GetMatchListVariables,
    GetMatchListVariablesCtx,
} from 'components/betting/MatchList';
import useMatchListVariables from 'components/betting/MatchList/useMatchListVariables';
import { useLinkedSportId, useRouterQuery } from 'hooks';
import { GetSportEventListByFiltersVariables } from 'types/gql.bet';
import { categorizerVars } from '../Categorizer/categorizerState';
import { useGetSportEventListByFilters } from '../MatchList/getSportEventListByFilters.bet';

const SUB_SPORT_MATCH_LIST_EVENTS_COUNT = 5;

interface Input {
    getMatchListVariables: GetMatchListVariables;
}

interface Output {
    withSubSportMatchList: boolean;
    subSportUrl: NextLinkProps['href'];
    subSportName: string | null;
    isSubSportEventsExist: boolean;
    getSubSportMatchListVariables: GetMatchListVariables;
}

const useMatchListWithSubSport = ({ getMatchListVariables }: Input): Output => {
    const [parentSportId, setParentSportId] = useState<string>('');
    const [isSubSportEventsExist, setIsSubSportEventsExist] =
        useState<boolean>(false);
    const categorizerState = useReactiveVar(categorizerVars);
    const { sportId = '', tournamentId = '' } = useRouterQuery();

    const mainSportId = !tournamentId ? sportId : '';

    const { linkedSportId = '' } = useLinkedSportId({ sportId: parentSportId });

    const getSubSportMatchListVariables = useCallback(
        (
            ctx: GetMatchListVariablesCtx
        ): GetSportEventListByFiltersVariables => ({
            ...getMatchListVariables(ctx),
            sportIds: [linkedSportId],
            offset: 0,
            limit: SUB_SPORT_MATCH_LIST_EVENTS_COUNT,
            matchStatuses: ACTIVE_SPORTEVENT_STATUSES,
            tournamentIds: [],
        }),
        [getMatchListVariables, linkedSportId]
    );

    const mainSportListVariables = useMatchListVariables({
        getMatchListVariables,
    });

    const subSportListVariables = useMatchListVariables({
        getMatchListVariables: getSubSportMatchListVariables,
    });

    const { data: mainSportData } = useGetSportEventListByFilters(() => ({
        variables: mainSportListVariables,
        fetchPolicy: 'cache-only',
    }));

    const { data: subSportData } = useGetSportEventListByFilters(() => ({
        variables: subSportListVariables,
        fetchPolicy: 'cache-only',
        skip: !parentSportId,
    }));

    const subSportName =
        categorizerState && categorizerState[linkedSportId]?.name;

    const withSubSportMatchList = !!linkedSportId && !!subSportName;

    const subSportCount = subSportData?.matches.count || 0;

    const subSportUrl = {
        pathname: AppLink.BETS,
        query: {
            sportId: linkedSportId,
        },
    };

    useEffect(
        () => () => {
            setParentSportId('');
        },
        [mainSportId]
    );

    useEffect(() => {
        if (!subSportCount) return;

        setIsSubSportEventsExist(true);
    }, [subSportCount]);

    useEffect(() => {
        const count = mainSportData?.matches.count;
        const sportEventsLength =
            mainSportData?.matches.sportEvents.length || 0;

        const hasMore = (count || 0) > sportEventsLength;

        if (hasMore || count === undefined) return;

        setParentSportId(mainSportId);
    }, [
        mainSportData?.matches.count,
        mainSportData?.matches.sportEvents.length,
        mainSportId,
    ]);

    return {
        withSubSportMatchList,
        subSportUrl,
        subSportName,
        isSubSportEventsExist,
        getSubSportMatchListVariables,
    };
};

export default useMatchListWithSubSport;
