import { isArray } from 'lodash';

import { AppLink } from 'types/applink';
import {
    GetSportEventListByFiltersVariables,
    SportEventOrder,
    SportEventType,
} from 'types/gql.bet';
import { getRouterQuery, isEsportId } from 'utils';
import { MATCH_LIST_EVENTS_COUNT, MatchListType } from './constants';
import { GetMatchListVariables, GetMatchListVariablesCtx } from './types';

export const makeMatchListVariablesGetter = (
    getPageVariables: (
        ctx: GetMatchListVariablesCtx
    ) => Partial<GetSportEventListByFiltersVariables>
): GetMatchListVariables => {
    const baseVariables: GetSportEventListByFiltersVariables = {
        offset: 0,
        limit: MATCH_LIST_EVENTS_COUNT,
        isTopMarkets: true,
        sportEventTypes: [SportEventType.Match],
    };

    return (ctx) => {
        const { query, sportIds, url } = ctx;
        const resultVariables: GetSportEventListByFiltersVariables = {
            ...baseVariables,
            ...getPageVariables(ctx),
        };

        if (!resultVariables.marketStatusesForSportEvent) {
            resultVariables.marketStatusesForSportEvent =
                resultVariables.marketStatuses;
        }

        if (query && !resultVariables.sportIds) {
            const { sportId = '', tournamentId } = getRouterQuery(query);

            if (tournamentId) {
                resultVariables.tournamentIds = isArray(tournamentId)
                    ? tournamentId
                    : [tournamentId];

                const isLive = url.startsWith(AppLink.LIVE);

                if (isLive) {
                    resultVariables.order = SportEventOrder.RankRecommended;
                } else {
                    resultVariables.order = SportEventOrder.RankStartTime;
                }
            }

            if (sportId) {
                resultVariables.sportIds = isArray(sportId)
                    ? sportId
                    : [sportId];
            }

            if (!sportId) {
                resultVariables.sportIds = sportIds;
            }
        }

        return resultVariables;
    };
};

export const sportIdsBySportType = (
    url: string,
    sportIds: string[]
): string[] => {
    if (url.includes(AppLink.ESPORTS)) {
        return sportIds.filter((sportId) => isEsportId(sportId));
    }

    if (url.includes(AppLink.SPORTS)) {
        return sportIds.filter((sportId) => !isEsportId(sportId));
    }

    return sportIds;
};

export const getMatchListTypeByUrl = (currentUrl: string): MatchListType => {
    if (currentUrl.includes(AppLink.NEXTTOGO)) return MatchListType.NEXTTOGO;

    if (currentUrl.includes(AppLink.LIVE)) return MatchListType.LIVE;

    return MatchListType.ALL;
};
