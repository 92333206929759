import { FC, memo, useCallback, useRef, useState } from 'react';
import cn from 'classnames';
import { noop } from 'lodash';
import { LinkProps as NextLinkProps } from 'next/dist/client/link';

import SmartLink from 'components/SmartLink';
import Spinner from 'components/Spinner';

interface Props {
    onClick?: ((page: number) => Promise<void>) | VoidFunction;
    text: string;
    href?: NextLinkProps['href'];
}

const ShowMoreButton: FC<Props> = ({ onClick = noop, text, href }) => {
    const [loading, setIsLoading] = useState<boolean>(false);
    const page = useRef<number>(0);

    const handleClick = useCallback(async () => {
        setIsLoading(true);
        await onClick(page.current + 1);
        page.current += 1;
        setIsLoading(false);
    }, [onClick]);

    const buttonClassName = cn(
        'cursor-pointer rounded-large bg-surface-middle px-4 py-2 font-bold uppercase text-primary-white typo-xs-1 hover:opacity-80'
    );

    if (loading) {
        return (
            <div className="flex justify-center">
                <Spinner />
            </div>
        );
    }

    return (
        <div className="my-2 flex items-center justify-center">
            {href ? (
                <SmartLink className={buttonClassName} href={href}>
                    {text}
                </SmartLink>
            ) : (
                <div className={buttonClassName} onClick={handleClick}>
                    {text}
                </div>
            )}
        </div>
    );
};

export default memo(ShowMoreButton);
